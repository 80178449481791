<script setup lang="ts">
    import type { IWidgetProps } from '~/composables/widgets';
    import type { ProductAssetData } from '~/@types/cms';

    /**
     * Asset widget for images or youtube videos
     */
    const props = defineProps<IWidgetProps<ProductAssetData>>();

    const isFullBleed = computed(() => props.data.style?.size === 'fullbleed');
</script>
<template>
    <atm-grid
        class="cms-product-asset relative"
        :no-margin="isFullBleed"
        :full-width="isFullBleed">
        <atm-asset
            :data="data.value || data.image"
            :video-preview="data.image"
            :style-size="data.style?.size"
            :full-bleed="isFullBleed"
            :class="[
                'col-span-2',
                {
                    'md:col-start-3 md:col-end-11': data.style?.size === 'text',
                    'md:col-span-12': props.data.style?.size === 'content' || isFullBleed,
                },
            ]" />
    </atm-grid>
</template>

<style scoped>
    .cms-product-asset :deep(img) {
        @apply w-full;
    }
</style>
